// _fonts.scss

// Importa a fonte Calibri do Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Calibri&display=swap');

// Define a fonte padrão para o corpo do documento como Calibri e define o tamanho da fonte como 16 pixels
body {
  font-family: 'Calibri', sans-serif; // Define a fonte como Calibri
  font-size: 16px; // Define o tamanho da fonte como 16 pixels
}
